<template>
	<v-form
	v-if="core"
	ref="form"
	@submit="e => e.preventDefault()"
	class="tw-grid tw-grid-cols-12 tw-w-full tw-gap-x-[10px] tw-overflow-hidden tw-transition-all tw-pt-[5px]"
	:class="{
		'tw-max-h-[46px]': isHidden
	}"
	core-form-1
	>
		<ButtonSlot
		v-if="campaignStatus.step > 2"
		_theme="light-gray"
		class="tw-col-span-12 tw-mb-[10px] tw-h-[36px]"
		@click="isHidden = !isHidden"
		>
			{{ isHidden === true? "voir infos d'implantation" : "cacher infos d'implantation" }}
		</ButtonSlot>

		<h3
		v-if="core.status === 'cancelled'"
		class="tw-col-span-12 tw-flex tw-justify-center tw-mb-[10px]"
		style="color: red"
		>
			Carotte annulée
		</h3>

		<v-combobox
		:items="displayNamePrefixes"
		item-text="name"
		item-value="id"
		return-object
		v-model="core.displayNamePrefix"
		outlined
		clearable
		dense
		label="Préfixe"
		tabindex="1"
		:disabled="isDisabled"
		class="tw-col-span-6"
		validate-on-blur
		/>

		<v-text-field
		tabindex="1"
		class="tw-col-span-6"
		label="ID de la carotte"
		v-model="core.displayName"
		dense
		outlined
		:disabled="isDisabled || core.displayNamePrefix === null"
		clearable
		validate-on-blur
		:hint="hintId"
		:rules="rulesId"
		/>

		<v-combobox
		class="tw-col-span-12"
		tabindex="1"
		:rules="[$rules.requiredRoad]"
		:items="roads"
		item-text="name"
		item-value="id"
		return-object
		v-model="core.road"
		outlined
		clearable
		dense
		validate-on-blur
		label="Nom de la voie*"
		:disabled="isDisabled"
		/>

		<v-select
		dense
		tabindex="1"
		class="tw-col-span-12"
		v-model="core.direction"
		:items="roadWayField"
		:item-text="item => item.name"
		:item-value="item => item.id"
		label="Sens de la voie"
		data-vv-name="select"
		outlined
		:disabled="isDisabled"
		validate-on-blur
		clearable
		/>

		<v-select
		dense
		tabindex="1"
		class="tw-col-span-12"
		v-model="core.lane"
		:items="formVMField"
		:item-text="item => item.name"
		:item-value="item => item.name"
		label="Localisation/Voie"
		data-vv-name="select"
		outlined
		:disabled="isDisabled"
		validate-on-blur
		clearable
		/>

		<v-text-field
		dense
		tabindex="1"
		class="tw-col-span-5"
		v-model="core.landmark"
		label="PR"
		outlined
		clearable
		:disabled="isDisabled"
		:rules="[
			$rules.onlyNumbers,
			$rules.FourMax,
			$rules.landmarkMustBeFilled(
				core.landmark,
				core.abscissa
			)
		]"
		validate-on-blur
		/>

		<span class="tw-col-span-2 tw-text-center tw-text-[25px]">
			+
		</span>

		<v-text-field
		tabindex="1"
		dense
		class="tw-col-span-5"
		v-model="core.abscissa"
		label="ABS"
		outlined
		clearable
		:disabled="isDisabled"
		:rules="[
			$rules.onlyNumbers,
			$rules.FourMax,
			$rules.abscissaMustBeFilled(
				core.landmark,
				core.abscissa
			)
		]"
		validate-on-blur
		/>

		<v-select
		tabindex="1"
		dense
		class="tw-col-span-12"
		v-model="core.lateralPosition"
		:items="lateralPosField"
		:item-text="item => item.name"
		:item-value="item => item.name"
		label="Latéralisation*"
		data-vv-name="select"
		outlined
		:disabled="isDisabled"
		:rules="[$rules.required]"
		validate-on-blur
		clearable
		/>

		<v-select
		dense
		tabindex="1"
		class="tw-col-span-12"
		v-model="core.structureType"
		:items="stTypeField"
		:item-text="item => item.name"
		:item-value="item => item.name"
		label="Type de Structure"
		data-vv-name="select"
		outlined
		:disabled="isDisabled"
		validate-on-blur
		clearable
		/>

		<v-select
		dense
		tabindex="1"
		class="tw-col-span-12"
		v-model="core.isFullStructure"
		:items="stCompleteField"
		:item-text="item => item.name"
		:item-value="item => item.bool"
		label="Structure Complète*"
		:rules="[$rules.requiredBoolean]"
		data-vv-name="select"
		outlined
		:disabled="isDisabled"
		validate-on-blur
		clearable
		/>

		<v-select
		tabindex="1"
		dense
		class="tw-col-span-12"
		:items="formDiameter"
		v-model="core.diameter"
		:rules="[$rules.requiredDiameter]"
		label="Diamètre*"
		outlined
		:disabled="isDisabled"
		validate-on-blur
		clearable
		/>

		<v-select
		v-if="campaign.coringType === 'CS'"
		tabindex="1"
		dense
		class="tw-col-span-12"
		:items="formDamages"
		v-model="core.damage"
		:item-text="item => $t(item.name)"
		item-value="name"
		label="Dégradation présente"
		outlined
		clearable
		:disabled="isDisabled"
		validate-on-blur
		/>

		<v-textarea
		v-if="core.damage === 'other'"
		tabindex="1"
		dense
		class="tw-col-span-12"
		v-model="core.otherDamage"
		label="Dégradation autre*"
		:rules="[$rules.required]"
		placeholder="Dégradation autre"
		rows="1"
		outlined
		auto-grow
		clearable
		:disabled="isDisabled"
		validate-on-blur
		/>

		<v-textarea
		tabindex="1"
		dense
		class="tw-col-span-12"
		v-model="core.note"
		label="Note Supplémentaire"
		outlined
		rows="1"
		:disabled="isDisabled"
		validate-on-blur
		auto-grow
		clearable
		/>

		<ButtonSlot
		v-if="image === null"
		v-show="!isDisabled"
		class="tw-col-span-12"
		@selectFile="addImage"
		_select-file-accept="image/png, image/jpeg"
		_theme="light-gray"
		>
			ajouter une image
		</ButtonSlot>
        
		<div
		v-else
		class="tw-col-span-12 tw-overflow-hidden"
		>
			<ButtonSlot
			_theme="light-gray"
			class="tw-w-full tw-mb-[10px]"
			@click="removeImage"
			v-show="!isDisabled"
			>
				supprimer l'image
			</ButtonSlot>

			<WaitingSlot 
			v-if="image === false"
			class="tw-w-full tw-h-[200px]"
			/>
            
			<div 
			v-else 
			class="tw-w-full tw-h-[200px] tw-flex tw-justify-center"
			>
				<img
				class="tw-max-w-full tw-max-h-full"
				:src="image"
				/>
			</div>
		</div>
	</v-form>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import core from "../../utils/core";

export default {
	name: "CoringCoreForm1",
	components: {

	},
	props: {
		isDisabled: {
			default: false,
		}
	},
	data(){
		return {
			isHidden: false,

			core: false,
			image: false,

			formRoads: [],
			roadWayField: [
				{id: 1, name: "Sens 1"},
				{id: 2, name: "Sens 2"},
				{id: 3, name: "Autre"}
			],
			formVMField: [
				{id: 1, name: "BDG"},
				{id: 2, name: "VR"},
				{id: 3, name: "VM"},
				{id: 4, name: "VL"},
				{id: 5, name: "VSVL"},
				{id: 6, name: "BAU"},
				{id: 7, name: "VD"},
				{id: 8, name: "VG"},
				{id: 9, name: "Trottoir"},
				{id: 10, name: "Parking"},
				{id: 11, name: "Voie bus"},
				{id: 12, name: "Piste cyclable"},
				{id: 13, name: "Plateforme"},
				{id: 14, name: "Giratoire"},
				{id: 15, name: "V1"},
				{id: 16, name: "V2"},
				{id: 17, name: "V3"},
				{id: 18, name: "V4"},
				{id: 19, name: "EE"},
				{id: 20, name: "EI"},
				{id: 21, name: "Bretelle"},
				{id: 22, name: "TPC"},
				{id: 23, name: "ITPC"},
				{id: 24, name: "Refuge"},
				{id: 25, name: "Accès de service"},
				{id: 26, name: "Ouvrage d'art"},
				{id: null, name: "Autre"}
			],
			lateralPosField: [
				{id: 1, name: "BdR G"},
				{id: 2, name: "Axe"},
				{id: 3, name: "BdR D"},
				{id: 4, name: "Autre"}
			],
			stTypeField: [
				{id: 1, name: "Souple"},
				{id: 2, name: "Bitumineuse épaisse"},
				{id: 3, name: "Inverse et semi-rigide (GTLH)"},
				{id: 4, name: "Rigide (béton)"},
				{id: 5, name: "Mixte"}
			],
			stCompleteField: [
				{bool: true, name: "Oui"},
				{bool: false, name: "Non"}
			],
			formDiameter: [
				50, 80, 100, 150, 200, 250
			],
			formDamages: [
				{id: 1, name: "degradation"},
				{id: 2, name: "tearing off"},
				{id: 3, name: "various cracks"},
				{id: 4, name: "longitudinal joint"},
				{id: 5, name: "crazing"},
				{id: 6, name: "longitudinal crack"},
				{id: 7, name: "transverse crack"},
				{id: 8, name: "pothole"},
				{id: 9, name: "cracks in slabs"},
				{id: 10, name: "sagging"},
				{id: 11, name: "glaze - penetrant"},
				{id: 12, name: "repair"},
				{id: 13, name: "corner break"},
				{id: 14, name: "hollow rut"},
				{id: 15, name: "rut edge"},
				{id: 16, name: "healthy area"},
				{id: 17, name: "other"}
			],
		};
	},
	computed: {
		...mapGetters("coring", [
			"editingCore", "campaign", "displayNamePrefixes", "roads", "campaignStatus", "cores"
		]),

		rulesId(){
			return this.core.displayNamePrefix === null ? [] : [this.$rules.required];
		},
		hintId(){
			for(const core of this.cores){
				if(
					core.id !== this.core.id &&
                    core.displayName === this.core.displayName
				) return "Cette ID est déjà utilisé.";
			}
			return "";
		}
	},
	watch: {
		editingCore(){
			if(this.editingCore !== false) this.init();
		}
	},
	methods: {
		...mapActions("coring", [
			"updateRoads", "refreshCore", "updateDNP"
		]),

		async submit(){
			if(this.$refs.form.validate()){
				await this.checkRoad();
				await this.checkDNP();
				return this.core;
			}
			else return false;
		},

		async checkRoad(){
			if(this.core.road.id === undefined){
				this.core.road = await this.$api.roads.create({
					name: this.core.road,
					category: "a_road",
					workspaceId: this.campaign.workspace.id,
				});
				await this.updateRoads();
			}
		},

		async checkDNP(){
			if(this.core.displayNamePrefix !== null && this.core.displayNamePrefix.id === undefined){
				await this.$api.displayNamePrefixes.create({
					name: this.core.displayNamePrefix,
					coringCampaignId: this.campaign.id,
				});
				await this.updateDNP();
			}
		},

		async addImage({target: {files: [file]}}){
			this.core.corePictures.push(await this.$api.corePictures.upload(file, {coreId: this.core.id, type: "technicianHelp"}));
			this.image = URL.createObjectURL(file);
			this.refreshCore(this.core);
			this.$forceUpdate();
		},

		async removeImage(){
			await this.$api.corePictures.remove(
				this.core.corePictures.find(value => value.type === "technicianHelp").id
			);
			this.core.corePictures = [];
			this.image = null;
			this.refreshCore(this.core);
			this.$forceUpdate();
		},

		async init(){
			if(this.campaignStatus.step > 2) this.isHidden = true;

			this.core = {...this.editingCore};
			let picture = this.core.corePictures.find(value => value.type === "technicianHelp");
			if(picture !== undefined){
				this.image = URL.createObjectURL(
					await this.$api.documents.download(
						this.campaign.workspace.site.organization.id + 
                        "/" + 
                        picture.path
					)
				);
			}
			else {
				this.image = null;
			}

			if(this.core.status === "cancelled") this.isHidden = false;
		}

	},
	created(){
        
	},
	mounted(){
		this.init();
	}
    
};
</script>

<style lang="scss">
form[core-form-1]{
    max-height: 1000px;

    .v-messages__wrapper {
        color: #ec7505;
    }
}
</style>
