<template>
  <section class="csvImportPopIn">
    <v-card flat outlined class="newCoringsValidationPopIn__card" id="modale">
      <v-card-title class="center justify-center" align-items>
        <h3>Importer depuis un fichier CSV</h3>
      </v-card-title>
      <div v-if="displayPrevisualisationStep === false">
        <v-card-text class="card_text">
          <p>Vous pouvez télécharger le fichier modèle afin de le remplir.</p>
          <p><a href="/public_data/csv_export_model.xlsx">Télécharger</a></p>
        </v-card-text>
        <div style="width: 400px; margin: auto">
          <v-file-input
            v-model="csvFile"
            accept=".csv"
            label="Votre fichier CSV"
          ></v-file-input>
        </div>
      </div>

      <div v-else>
        <v-alert
          style="text-align: left"
          v-if="null !== error && displayFullError === false"
          v-html="errorTruncated + '...'"
          type="error"
        ></v-alert>
        <MediumButtonSlot
          v-if="null !== error && displayFullError === false"
          @click="displayFullError = true"
        >
          Afficher plus
        </MediumButtonSlot>
        <v-alert
          style="text-align: left"
          v-if="null !== error && displayFullError === true"
          v-html="error"
          type="error"
        ></v-alert>
        <v-simple-table fixed-header height="600px" v-if="null === error">
          <template v-slot:default>
            <thead class="table">
              <tr>
                <th class="text-left">Route*</th>
                <th class="text-left">Type de structure*</th>
                <th class="text-left">Diamètre*</th>
                <th class="text-left">Structure pleine</th>
                <th class="text-left">Voie*</th>
                <th class="text-left">Direction*</th>
                <th class="text-left">PR</th>
                <th class="text-left">ABS</th>
                <th class="text-left">Lat</th>
                <th class="text-left">Long</th>
                <th class="text-left">Latéralisation</th>
                <th class="text-left">Epaisseur totale*</th>
                <th class="text-left">Nom d'affichage</th>
                <th class="text-left">Commentaire</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, key) in csvData" :key="key">
                <td>{{ data[0] }}</td>
                <td>{{ data[1] }}</td>
                <td>{{ data[2] }}</td>
                <td>{{ data[3] }}</td>
                <td>{{ data[4] }}</td>
                <td>{{ data[5] }}</td>
                <td>{{ data[6] }}</td>
                <td>{{ data[7] }}</td>
                <td>{{ data[8] }}</td>
                <td>{{ data[9] }}</td>
                <td>{{ data[10] }}</td>
                <td>{{ data[11] }}</td>
                <td>{{ data[12] }}</td>
                <td>{{ data[13] }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <v-card-actions>
        <ButtonSlot
          v-if="displayPrevisualisationStep === false"
          @click="closePopin"
          _theme="light-gray"
        >
          Annuler
        </ButtonSlot>
        <ButtonSlot
          v-if="displayPrevisualisationStep === true"
          @click="goBack()"
          _theme="light-gray"
        >
          Retour
        </ButtonSlot>
        <v-spacer></v-spacer>
        <ButtonSlot
          v-if="null !== csvFile && displayPrevisualisationStep === false"
          @click="previsualize"
        >
          Prévisualiser
        </ButtonSlot>
        <ButtonSlot
          v-if="displayPrevisualisationStep === true"
          @click="importCsv"
          :_loading="loading"
          :_disabled="btnDisabled"
        >
          Valider l'importation
        </ButtonSlot>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import Parser from "papaparse";
import toastPlugin from "@/plugins/messages";
import { mapActions } from 'vuex';

export default {
  name: "CsvImportPopIn",
  data() {
    return {
      csvFile: null,
      displayPrevisualisationStep: false,
      csvData: [],
      loading: false,
      btnDisabled: false,
      error: null,
      errorTruncated: null,
      displayFullError: false
    };
  },
  methods: {
    ...mapActions("coring", ["updateCores"]),

    closePopin() {
      this.$emit("closePopin", false);
    },
    previsualize() {
      Parser.parse(this.csvFile, {
        complete: results => {
          this.csvData = results.data;
          this.csvData.shift();
          document.getElementById("modale").setAttribute("style", "width:80%");
          this.displayPrevisualisationStep = true;
        },
        skipEmptyLines: true,
        encoding: "ISO-8859-1"
      });
    },
    importCsv() {
      this.loading = true;
      let formData = new FormData();
      formData.append("csv", this.csvFile);

      this.$axios
        .post(
          "coring-campaigns/" + this.$route.params.id + "/cores/csv-import",
          formData
        )
        .then(async () => {
          await this.updateCores();
          toastPlugin.toast("success", "Importation terminée !");
          this.goBack();
          this.closePopin();
        })
        .catch(error => {
          this.error = error.response.data.message;
          this.errorTruncated = error.response.data.message.slice(0, 250);
          this.loading = false;
          this.btnDisabled = true;
        });
    },
    goBack() {
      this.loading = false;
      this.error = null;
      this.csvFile = null;
      this.displayPrevisualisationStep = false;
      this.btnDisabled = false;
      this.displayFullError = false;
      document.getElementById("modale").setAttribute("style", "width:40%");
    }
  },

  mounted() {
    this.$root.$on("closeCurrent", this.closePopin);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.csvImportPopIn {
  @extend %popinView;

  .table {
    th {
      text-align: center !important;
    }
  }

  .v-card {
    width: 40%;
    text-align: center;
    padding: 20px 20px;
    max-height: 90%;
    overflow: auto;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }
}
</style>
