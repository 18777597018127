<template>
	<section class="coringCopyPopIn">
		<v-card
		flat
		outlined
		class="coringCopyPopIn__card"
		>
			<v-card-title
			class="center justify-center"
			align-items
			>
				<h3>{{ title }}</h3>
			</v-card-title>

			<v-card-subtitle
			align-items
			class="tw-mt-[10px] tw-flex justify-center"
			>
				<p>
					Les carottes contenant le tag <v-chip
					color="orange"
					text-color="white"
					small
					class="tw-mr-[5px] tw-ml-[5px]"
					>
						Attention
					</v-chip> ont déjà des couches
				</p>
			</v-card-subtitle>

			<div class="tw-items-center tw-flex tw-flex-row tw-justify-center tw-flex-wrap">
				<div
				v-for="core in coresList"
				:key="core.id"  
				class="tw-flex tw-gap-[10px] tw-items-center tw-justify-center"
				:class="coresList.length > 3 ? 'lg:tw-basis-[calc(50%-5px)] tw-basis-full' : 'tw-basis-full'"
				>
					<div class="tw-flex tw-flex-row tw-items-center tw-mb-[10px]">
						<v-checkbox 
						class="tw-mr-[10px]"
						:label="core.displayNamePrefix !== null 
							? 'Carotte ' + core.displayName + ' ' + core.displayNamePrefix 
							: 'Carotte n°' + core.number"
						:value="core.id"
						v-model="selectedTargetCores"
						hide-details
						/>

						<v-chip
						v-if="core.layersAndInterfaces.length !== 0"
						color="orange"
						text-color="white"
						small
						>
							Attention
						</v-chip>
					</div>
				</div>
			</div>

			<v-card-actions>
				<MediumButtonSlot @click="closePopin">
					Annuler
				</MediumButtonSlot>

				<v-spacer/>

				<ButtonSlot
				@click="copyCoringLayer"
				:_popup="{
					title: 'Copier les couches sur les ' + this.selectedTargetCores.length +' carotte(s) sélectionnée(s) ?',
					subTitle: 'Êtes-vous sûr de vouloir effectuer cette action ?\nElle sera irréversible.',
				}"
				>
					Valider
				</ButtonSlot>
			</v-card-actions>
		</v-card>
	</section>
</template>

<script>
export default {
	name: "CoringCopyPopIn",
	props: {
		core: {
			default: false
		}
	},
	data(){
		return {
			coresList: [],
			loading: true,
			coreToCopy: null,
			selectedTargetCores: [],
			title: null
		};
	},
	methods: {
		closePopin(){
			this.$emit("closeCurrent", false);
		},

		copyCoringLayer(){
			this.$api.coreLayers.copyLayersToCore(this.core.id, this.selectedTargetCores).then(
				() => {
					this.$toast("success", "Couches copiées sur " + this.selectedTargetCores.length + " carottes");
					this.closePopin();
				}
			);
		},
	},
	mounted(){
		this.$root.$on("closeCurrent", this.closePopin);
		this.title = this.core.displayNamePrefix !== null 
			? "Copier les couches de la carotte" + this.core.displayName + this.core.displayNamePrefix 
			: "Copier les couches de la carotte n°" + this.core.number;
		this.title += " vers les carottes suivantes :";
		this.$api.cores.findByCampaign(this.$route.params.id).then(response => {
			this.coresList = response.filter(core => core.status !== "cancelled" && core.number !== this.core.number);
			this.loading = false;
		});
	}
};

</script>

<style lang="scss" scoped>

@import "@/assets/scss/_extends.scss";

.coringCopyPopIn {
  @extend %popinView;

  .table {
    th {
      text-align: center !important;
    }
  }

  .v-card {
    width: 40%;
    text-align: center;
    padding: 20px 20px;
    max-height: 90%;
    overflow: auto;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .v-input--selection-controls {
	margin-top: 0px !important;
  }

  
}

</style>
