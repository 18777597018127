var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"coring-core-form":""}},[_c('h2',[_vm._v("Carotte #"+_vm._s(_vm.editingCore.number))]),_c('section',{staticClass:"tw-grow tw-overflow-y-auto tw-pr-[5px]"},[_c('CoringCoreForm1',{ref:"form1",attrs:{"isDisabled":_vm.campaign.status !== 'awaiting_validation'}}),(_vm.campaignStatus.step >= 3)?_c('CoringCoreForm2',{ref:"form2",attrs:{"isDisabled":_vm.campaign.status !== 'taken'}}):_vm._e()],1),_c('section',[_c('div',{staticClass:"container-button"},[_c('ButtonSlot',{staticClass:"tw-w-[122px]",attrs:{"_theme":"light-gray"},on:{"click":function($event){return _vm.STOP_EDITING_CORE()}}},[_vm._v(" annuler ")]),(
				(
					_vm.editingCore.status === 'sent_to_study_manager' || 
					_vm.editingCore.status === 'empty' ||
					_vm.editingCore.status === 'waiting' ||
					_vm.editingCore.status === 'validated'
				) &&
					_vm.campaign.status !== 'programing_validated' &&
					_vm.campaignStatus.step < 4
			)?_c('ButtonSlot',{attrs:{"_auto-loading":""},on:{"click":_vm.sumbited}},[_vm._v(" valider ")]):_vm._e()],1),_c('div',{staticClass:"container-button tw-mt-[10px]"},[(_vm.campaign.status === 'awaiting_validation')?_c('ButtonSlot',{staticClass:"tw-w-[122px]",attrs:{"_theme":"light-gray","_popup":{
				title: 'Supprimer la carotte?',
				subTitle: 'Êtes-vous sûr de vouloir effectuer cette action ?\nElle sera irréversible.',
			}},on:{"click":_vm.removeCore}},[_vm._v(" supprimer ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }