<template>
	<div class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<CsvImportPopin
		v-if="campaign.status === 'awaiting_validation'"
		v-show="csvImportPopInDisplay"
		@closePopin="csvImportPopInDisplay = false"
		/>

		<SendCoringCampaignToProviderValidationPopin
		v-if="campaign.status === 'taking_validated'"
		:site="campaign.workspace.site"
		v-show="isCoringCampaignSendDataPopinDisplay"
		@closePopin="isCoringCampaignSendDataPopinDisplay = false"
		:campaign="campaign"
		:needButton="true"
		/>

		<HeaderSlot
		@clickBackButton="$router.back()"
		:title="campaign.name || ''"
		:subTitle="campaignStatus.label || ''"
		>
			<ButtonSlot
			@click="getTemporaryPDF()"
			v-if="campaignStatus.step > 3"
			_theme="light-gray"
			_icon="mdi-file"
			:_res="1150"
			>
				Rapport provisoire
			</ButtonSlot>

			<ButtonSlot
			@click="refreshData()"
			v-if="campaign.status === 'sent_for_analysis'"
			:_res="1150"
			_icon="mdi-refresh"
			>
				Rafraîchir les données
			</ButtonSlot>

			<ButtonSlot
			v-if="campaignStatus.step > 5"
			:_to="
				campaign.coringType == 'CA'
					? {
						name: 'coring-campaign-dashboard',
						params: { id: $route.params.id }
					}
					: {
						name: 'structural-coring-campaign-dashboard',
						params: { id: $route.params.id }
					}
			"
			:_res="1150"
			_icon="mdi-file-document-outline"
			>
				Accéder aux données
			</ButtonSlot>

			<ButtonSlot
			@click="setCampaignStatus('programing_validated')"
			:_disabled="!isCoresValid"
			v-if="campaign.status === 'awaiting_validation' && $hasRight('campaigns.configureCoringAndStart')"
			_icon="mdi-check-outline"
			:_tooltip="!isCoresValid ? 'Certaines carottes ne sont pas validées, impossible de valider la campagne.' : ''"
			:_res="1150"
			:_popup="{
				title: 'Valider le placement des carottes ?',
				subTitle: 'Voulez-vous soumettre votre campagne à votre technicien de carottage ?\nAprès envoi, cette campagne ne sera plus modifiable.'
			}"
			_auto-loading
			>
				valider la programmation
			</ButtonSlot>

			<ButtonSlot
			@click="validateCampaing"
			:_disabled="!isCoresValid"
			v-if="campaign.status === 'taken' && $hasRight('campaigns.configureCoringAndStart')"
			_icon="mdi-check-outline"
			:_tooltip="!isCoresValid ? 'Certaines carottes ne sont pas validées, impossible de valider la campagne.' : ''"
			:_res="1150"
			:_popup="{
				title: 'Valider le relevé des carottes ?',
				subTitle: 'Si vous confirmez cette action vous ne pouvez plus revenir en arrière.'
			}"
			_auto-loading
			>
				valider le relevé des carottes
			</ButtonSlot>

			<ButtonSlot
			@click="!hasPrestations ? redirectToPrestationSettings() : isCoringCampaignSendDataPopinDisplay = true "
			v-if="campaign.status === 'taking_validated' && $hasRight('campaigns.configureCoringAndStart')"
			:_icon="!hasPrestations ? 'mdi-pencil-outline' : 'mdi-check-outline'"
			:_res="1150"
			>
				{{ !hasPrestations ? 'Paramétrer les prestations' : 'Envoyer au laboratoire' }}
			</ButtonSlot>
		</HeaderSlot>

		<div class="tw-grow tw-overflow-hidden tw-flex tw-flex-col lg:tw-flex-row tw-gap-[10px]">
			<CoringMap 
			ref="coringMap"
			class="tw-h-[calc(50%-10px)] tw-w-full lg:tw-w-[calc(70%-10px)] lg:tw-h-full"
			/>

			<div class="tw-h-[50%] tw-w-full tw-flex tw-flex-col lg:tw-w-[calc(30%)] lg:tw-overflow-hidden lg:tw-h-full lg:tw-min-w-[340px] tw-gap-[10px]">
				<div 
				v-if="!editingCore && campaign.status === 'awaiting_validation'" 
				class="tw-flex tw-full tw-justify-center tw-gap-[5px]"
				>
					<ButtonSlot
					v-if="campaign.status === 'awaiting_validation'"
					@click="isAddingCore === false? START_ADDING_CORE() : STOP_ADDING_CORE()"
					:class="{ 'tw-opacity-[0.5]': isAddingCore }"
					_icon="mdi-map-marker-plus-outline"
					>
						{{ !isAddingCore ? "Ajouter une carotte" : "annuler l'ajout de carotte" }}
					</ButtonSlot>

					<ButtonSlot
					v-if="campaign.status === 'awaiting_validation'"
					@click="csvImportPopInDisplay = true"
					_icon="mdi-file"
					_theme="light-gray"
					>
						Importation CSV
					</ButtonSlot>
				</div>

				<div
				v-if="!editingCore"
				class="tw-grow tw-overflow-hidden tw-overflow-y-auto tw-flex tw-flex-col tw-gap-[10px] tw-pr-[5px]"
				>
					<CoringCoreCard
					v-for="core of cores"
					:key="core.number"
					:core="core"
					class="tw-shrink-0"
					/>
				</div>

				<CoringCoreForm
				v-if="editingCore"
				class="tw-h-full tw-w-full"
				/>
			</div>
		</div>

		<SiteDeactivated v-if="isSiteDeactivated"/>
	</div>
</template>

<script>
import CoringMap from "../../components/coring/Coring.Map.vue";
import CoringCoreCard from "../../components/coring/Coring.Core.Card.vue";
import CoringCoreForm from "../../components/coring/Coring.Core.Form";
import CsvImportPopin from "../../components/coring/CsvImport.popin.vue";
import SendCoringCampaignToProviderValidationPopin from "@/components/providers/sendCoringCampaignToProviderValidation.popin.vue";
import {mapGetters, mapActions, mapMutations} from "vuex";
import SiteDeactivated from "../../components/campaign/SiteDeactivated.vue";

export default {
	name: "CoringCampaign",
	components: {
		CoringMap,
		CoringCoreCard,
		CoringCoreForm,
		CsvImportPopin,
		SendCoringCampaignToProviderValidationPopin,
		SiteDeactivated
	},
	data(){
		return {
			csvImportPopInDisplay: false,
			kmlImportPopInDisplay: false,
			isCoringCampaignSendDataPopinDisplay: false,
			hasPrestations: false,
			isSiteDeactivated: false
		};
	},
	computed: {
		...mapGetters("coring", [
			"campaign", "cores", "editingCore", "isCoresValid", "campaignStatus", "isAddingCore"
		]),
	},
	watch: {
		isAddingCore(){
			if(this.isAddingCore !== false){
				window.onkeydown = (e) => {
					if(e.key === "Escape") this.STOP_ADDING_CORE();
				};
			}
			else {
				window.onkeydown = () => {};
			}
		}
	},
	methods: {
		...mapMutations("coring", [
			"START_ADDING_CORE", "STOP_ADDING_CORE", "STOP_EDITING_CORE"
		]),
		...mapActions("coring", [
			"initStore", "purgeStore", "setCampaignStatus", "updateCores", "setCampaign"
		]),

		redirectToPrestationSettings(){
			this.$router.push({
				name: "coring-campaign-prestations",
				params: {id: this.$route.params.id}
			});
		},
		async getTemporaryPDF(){
			const response = await this.$api.coringCampaigns.getTemporaryPDF(
				this.$route.params.id
			);
			var newBlob = new Blob([response], {type: "application/pdf"});
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = this.campaign.name;
			window.open(link, "_blank");
		},

		async validateCampaing(){
			if(this.campaign.coringType === "CS"){
				this.STOP_EDITING_CORE();
				await this.setCampaignStatus("results_obtained");
				this.$router.push({
					name: "structural-coring-campaign-dashboard",
					params: {id: this.$route.params.id}
				});
			}
			else {
				await this.setCampaignStatus("taking_validated");
			}
		},

		async refreshData(){
			await this.$api.coringCampaigns.getFromProvider(this.$route.params.id);
			await this.setCampaign(this.$route.params.id);
		},

		async hasCampaignPrestations(){
			const prestations = await this.$api.campaigns.getPrestations(
				this.$route.params.id
			);
			this.hasPrestations = prestations.length != 0;
		}
	},
	async mounted(){
		let response = await this.initStore({id: this.$route.params.id});
		
		if(response === "site_deactivated"){
			this.isSiteDeactivated = true;
			return;
		}

		this.hasCampaignPrestations();
	},
	destroyed(){
		this.purgeStore();
	}
};
</script>

<style lang="scss">

</style>
