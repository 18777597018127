<template>
	<div
	coring-core-form
	>
		<h2>Carotte #{{ editingCore.number }}</h2>

		<section class="tw-grow tw-overflow-y-auto tw-pr-[5px]">
			<CoringCoreForm1
			ref="form1"
			:isDisabled="campaign.status !== 'awaiting_validation'"
			/>

			<CoringCoreForm2
			ref="form2"
			v-if="campaignStatus.step >= 3"
			:isDisabled="campaign.status !== 'taken'"
			/>
		</section>

		<section>
			<div class="container-button">
				<ButtonSlot
				@click="STOP_EDITING_CORE()"
				_theme="light-gray"
				class="tw-w-[122px]"
				>
					annuler
				</ButtonSlot>

				<ButtonSlot
				@click="sumbited"
				_auto-loading
				v-if="
					(
						editingCore.status === 'sent_to_study_manager' || 
						editingCore.status === 'empty' ||
						editingCore.status === 'waiting' ||
						editingCore.status === 'validated'
					) &&
						campaign.status !== 'programing_validated' &&
						campaignStatus.step < 4
				"
				>
					valider
				</ButtonSlot>
			</div>

			<div class="container-button tw-mt-[10px]">
				<ButtonSlot
				v-if="campaign.status === 'awaiting_validation'"
				@click="removeCore"
				_theme="light-gray"
				:_popup="{
					title: 'Supprimer la carotte?',
					subTitle: 'Êtes-vous sûr de vouloir effectuer cette action ?\nElle sera irréversible.',
				}"
				class="tw-w-[122px]"
				>
					supprimer
				</ButtonSlot>
			</div>
		</section>
	</div>
</template>

<script>
import CoringCoreForm1 from "./Coring.Core.Form.1.vue";
import CoringCoreForm2 from "./Coring.Core.Form.2.vue";
import {mapGetters, mapMutations, mapActions} from "vuex";

export default {
	name: "CoringCoreForm",
	components: {
		CoringCoreForm1,
		CoringCoreForm2
	},
	props: {

	},
	data(){
		return {
            
		};
	},
	watch: {
		core(){
            
		}
	},
	computed: {
		...mapGetters("coring", [
			"editingCore", "campaign", "campaignStatus"
		]),
	},
	methods: {
		...mapMutations("coring", ["STOP_EDITING_CORE"]),
		...mapActions("coring", [
			"updateCore", "deleteCore", "updateCores"
		]),

		async removeCore(){
			await this.deleteCore(this.editingCore);
			this.STOP_EDITING_CORE();
		},

		async sumbited(){
			await new Promise(resolve => setTimeout(resolve));
			if(this.campaign.status === "awaiting_validation"){
				let result = await this.$refs.form1.submit();
				if(result === false) return;
				this.updateCore({...result, status: "waiting"});
				this.STOP_EDITING_CORE();
			}
			else if(this.campaign.status === "taken"){
				let result = await this.$refs.form2.submit();
				if(result === false) return;
				this.updateCore({...result});
				this.STOP_EDITING_CORE();
			}
		}

	},
	created(){
        
	},
	mounted(){

	}
    
};
</script>

<style lang="scss">
div[coring-core-form]{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    height: 100%;

    .container-button{
        display: flex;
        justify-content: space-between;
    }
}
</style>
