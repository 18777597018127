<template>
	<div
	v-if="core !== false"
	:style="{'order': core.number, '--bg-statut-color': infoStatusCore.color}"
	coring-core-card
	>
		<div class="first-part">
			<h3>Carotte #{{ core.number }}</h3>

			<span :title="infoStatusCore.label">
				<!-- <v-icon>{{infoStatusCore.icon}}</v-icon> -->
				<label>{{ label }}</label>
			</span>
		</div>

		<div class="second-part">
			<ButtonSlot
			@click="map.jumpTo({center: [core.lng, core.lat]})"
			_icon="mdi-crosshairs-gps"
			_icon-size="22px"
			_theme="none"
			_full-rounded
			_small
			/>

			<ButtonSlot
			@click="removeCore"
			v-if="campaign.status === 'awaiting_validation'"
			:_popup="{
				title: 'Supprimer la carotte?',
				subTitle: 'Êtes-vous sûr de vouloir effectuer cette action ?\nElle sera irréversible.',
			}"
			_icon="mdi-delete"
			_icon-size="22px"
			_theme="none"
			_full-rounded
			_small
			_auto-loading
			/>

			<ButtonSlot
			@click="START_EDITING_CORE(core)"
			:_icon="
				(
					core.status === 'sent_to_study_manager' || 
					core.status === 'empty' ||
					core.status === 'waiting' ||
					core.status === 'validated'
				) &&
					campaign.status !== 'programing_validated' &&
					campaignStatus.step < 4
					?
						'mdi-pencil' :
						'mdi-eye'
			"
			_icon-size="22px"
			_theme="none"
			_full-rounded
			_small
			/>
		</div>
	</div>
</template>

<script>
import coring from "../../utils/coring";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
	name: "CoringCard",
	components: {
        
	},
	props: {
		core: {
			default: false
		}
	},
	data(){
		return {
			infoStatusCore: false,
		};
	},
	computed:{
		...mapGetters("coring", ["map", "campaign", "campaignStatus"]),

		label(){
			if(this.campaign.status === "awaiting_validation" && this.core.status === "waiting")return "Rempli";
			return this.infoStatusCore.label;
		}
	},
	watch: {
		core(){
			if(this.core !== false){
				this.init();
			}
		}
	},
	methods: {
		...mapMutations("coring", ["START_EDITING_CORE"]),
		...mapActions("coring", ["deleteCore"]),

		async removeCore(){
			await this.deleteCore(this.core);
		},

		init(){
			this.infoStatusCore = coring.getInfoStatusCore(this.core);
		}

	},
	created(){
		this.init();
	}
    
};
</script>

<style lang="scss">
div[coring-core-card]{
    overflow: hidden;
    user-select: none;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: solid rgba(0,0,0,0.12) 1px;
    display: flex;
    gap: 5px;
    align-items: center;

    > .first-part{
        display: flex;
        gap: 5px;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;

        > span {
            background: var(--bg-statut-color);
            color: white;
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 2px 7px;
            border-radius: 999px;
            font-size: 12px;
            overflow: hidden;
            min-width: 12px;

            > .v-icon {
                color: white;
                font-size: 12px;
            }

            > label{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex-shrink: 1;

                &::before{
                    position: fixed;
                    content: attr(tooltip);
                }
            }

        }
    }

    > .second-part{
        overflow: hidden;
        flex-shrink: 0;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        gap: 5px;
    }
}
</style>
